import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './D3Chart.css'; // Import the CSS file

const renderChart = (node, data, width, height) => {
    if (!data || data.length === 0) return;

    const margin = { top: 20, right: 60, bottom: 30, left: 40 };
    const svgWidth = width - margin.left - margin.right;
    const svgHeight = height - margin.top - margin.bottom;

    const svg = d3.select(node)
        .html('')  // Clear previous content
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

        const x = d3.scaleTime()
            .domain(d3.extent(data, d => new Date(d.time)))
            .range([0, svgWidth]);

        const y = d3.scaleLinear()
            .domain([d3.min(data, d => d.low), d3.max(data, d => d.high)])
            .nice()
            .range([svgHeight, 0]);

        const xAxis = g => g
            .attr('transform', `translate(0,${svgHeight})`)
            .call(d3.axisBottom(x).tickSize(0).tickPadding(10));

        const yAxisRight = g => g
            .attr('transform', `translate(${svgWidth},0)`)
            .call(d3.axisRight(y).tickSize(0).tickPadding(10))
            .call(g => g.select(".domain").remove());

        svg.append('g')
            .call(xAxis);

        svg.append('g')
            .call(yAxisRight);

        svg.selectAll('rect')
            .data(data)
            .enter().append('rect')
            .attr('x', d => x(new Date(d.time)))
            .attr('y', d => y(d3.max([d.open, d.close])))
            .attr('width', 5)
            .attr('height', d => Math.abs(y(d.open) - y(d.close)))
            .attr('fill', d => d.open > d.close ? 'red' : 'green')
            .attr('stroke', '#000')
            .attr('stroke-width', 1);

        svg.selectAll('line')
            .data(data)
            .enter().append('line')
            .attr('x1', d => x(new Date(d.time)) + 2.5)
            .attr('x2', d => x(new Date(d.time)) + 2.5)
            .attr('y1', d => y(d.high))
            .attr('y2', d => y(d.low))
            .attr('stroke', '#000')
            .attr('stroke-width', 1);

        svg.style('image-rendering', 'pixelated');
};

const D3Chart = ({ data }) => {
    const chartRef = useRef(null);
    const resizeObserver = useRef(null);

    useEffect(() => {
        if (!resizeObserver.current) {
            resizeObserver.current = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    const { width, height } = entry.contentRect;
                    requestAnimationFrame(() => renderChart(chartRef.current, data, width, height));
                }
            });
        }

        if (chartRef.current) {
            resizeObserver.current.observe(chartRef.current);
        }

        return () => {
            if (chartRef.current) {
                resizeObserver.current.unobserve(chartRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (chartRef.current) {
            const { width, height } = chartRef.current.getBoundingClientRect();
            requestAnimationFrame(() => renderChart(chartRef.current, data, width, height));
        }
    }, [data]);

    return <div ref={chartRef} className="d3-chart-container"></div>;
};

export default D3Chart;
