import React, { useState, useEffect } from 'react';

const SearchBox = ({ symbols, onSymbolSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSymbols, setFilteredSymbols] = useState([]);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredSymbols([]);
    } else {
      const results = symbols.filter(symbol =>
        symbol.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredSymbols(results);
    }
  }, [searchTerm, symbols]);

  const handleSelectSymbol = (symbol) => {
    setSearchTerm('');
    setFilteredSymbols([]);
    onSymbolSelect(symbol);
  };

  return (
    <div>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search symbols..."
      />
      {filteredSymbols.length > 0 && (
        <ul>
          {filteredSymbols.map((symbol) => (
            <li key={symbol} onClick={() => handleSelectSymbol(symbol)}>
              {symbol}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBox;
