import React, { useState } from 'react';
import TopBar from './components/TopBar/TopBar';
import WidgetContainer from './components/WidgetContainer/WidgetContainer';

const App = () => {
  const apiKey = 'UGX6XLQSM0JX75WS';
  const [widgets, setWidgets] = useState([]);

  const addWidget = (selectedWidget) => {
    console.log('Adding new widget:', selectedWidget);
    const newWidget = { id: widgets.length + 1, type: selectedWidget };
    setWidgets([...widgets, newWidget]);
  };

  return (
    <div className="App">
      <TopBar onAddWidget={addWidget} />
      <WidgetContainer widgets={widgets} apiKey={apiKey} />
    </div>
  );
};

export default App;
