import React, { useEffect, useState } from 'react';
import { connectToBinanceWebSocket, fetchSymbols, fetchHistoricalData } from '../../services/cryptoService';
import TimeframeDropdown from '../TimeframeDropdown/TimeframeDropdown';
import SearchBox from '../SearchBox/SearchBox';
import D3Chart from '../D3Chart/D3Chart';
import './PixelChart.css'; // Import the CSS file

const PixelChart = () => {
  const [data, setData] = useState([]);
  const [timeframe, setTimeframe] = useState('1m');
  const [symbols, setSymbols] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState('btcusdt');

  useEffect(() => {
    const loadSymbols = async () => {
      const fetchedSymbols = await fetchSymbols();
      setSymbols(fetchedSymbols);
    };
    loadSymbols();
  }, []);

  useEffect(() => {
    const loadHistoricalData = async () => {
      const historicalData = await fetchHistoricalData(selectedSymbol, timeframe);
      setData(historicalData);
    };
    loadHistoricalData();

    const disconnect = connectToBinanceWebSocket(selectedSymbol, timeframe, (newCandle) => {
      setData((prevData) => {
        const updatedData = prevData.slice();
        const lastCandle = updatedData[updatedData.length - 1];

        if (lastCandle && new Date(lastCandle.time).getMinutes() === new Date(newCandle.time).getMinutes()) {
          updatedData[updatedData.length - 1] = newCandle;
        } else {
          updatedData.push(newCandle);
        }

        return updatedData;
      });
    });

    return () => disconnect();
  }, [timeframe, selectedSymbol]);

  return (
    <div className="pixel-chart-container">
      <div className="selected-symbol-label">Selected Symbol: {selectedSymbol.toUpperCase()}</div>
      <SearchBox symbols={symbols} onSymbolSelect={setSelectedSymbol} />
      <TimeframeDropdown selectedTimeframe={timeframe} onTimeframeChange={setTimeframe} />
      <D3Chart data={data} />
    </div>
  );
};

export default PixelChart;
