import React from 'react';
import Dropdown from '../Dropdown/Dropdown';
import widgetOptions from '../../constants/widgetOptions';

const WidgetDropdown = ({ selectedWidget, onWidgetChange }) => {
    return (
        <Dropdown
            label="Select Widget"
            options={widgetOptions}
            selectedValue={selectedWidget}
            onChange={onWidgetChange}
        />
    );
};

export default WidgetDropdown;
