import React from 'react';

const Dropdown = ({ options, selectedValue, onChange, label }) => {
  return (
        <div className="dropdown">
            <label>{label}</label>
            <select value={selectedValue} onChange={e => onChange(e.target.value)}>
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Dropdown;
