import React, {useState} from 'react';
import './TopBar.css';
import WidgetDropdown from '../WidgetDropdown/WidgetDropdown';

const TopBar = ({ onAddWidget }) => {
    const [widget, setWidget] = useState('1');

    const handleClick = () => {
        console.log('Button clicked, adding widget:', widget);
        onAddWidget(widget);
    };

    return (
        <div className="top-bar">
            <h1>degen horus</h1>
            <WidgetDropdown selectedWidget={widget} onWidgetChange={setWidget}/>
            <button onClick={handleClick}>Add Widget</button>
        </div>
    );
};

export default TopBar;
