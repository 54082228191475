import React from 'react';
import { ResizableBox } from 'react-resizable';
import PixelChart from '../PixelChart/PixelChart';
import FinancialNews from '../FinancialNews/FinancialNews';
import widgetOptions from '../../constants/widgetOptions';
import 'react-resizable/css/styles.css';
import './WidgetContainer.css'; // Import the CSS file for widget container

// Debounce function to limit the rate of function calls
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const WidgetContainer = ({ widgets, apiKey }) => {
  const renderWidget = (widget) => {
    const widgetComponent = (() => {
      switch (widget.type) {
        case widgetOptions.id = '1':
          return <PixelChart />;
        case widgetOptions.id = '2':
          return <FinancialNews apiKey={apiKey} />;
        default:
          return null;
      }
    })();

    return (
      <ResizableBox
        key={widget.id}
        width={300}
        height={300}
        minConstraints={[150, 150]}
        maxConstraints={[800, 600]}
        className="widget-box"
        onResize={debounce(() => {}, 100)}
      >
        <div className="widget-content">
          {widgetComponent}
        </div>
      </ResizableBox>
    );
  };

  return (
    <div className="widget-container">
      {widgets.map((widget) => renderWidget(widget))}
    </div>
  );
};

export default WidgetContainer;
