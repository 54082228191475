import React, { useState, useEffect } from 'react';

const FinancialNews = ({ apiKey }) => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                debugger;
                const topics = "blockchain"
                const response = await fetch(`https://www.alphavantage.co/query?function=NEWS_SENTIMENT&topics=${topics}&apikey=${apiKey}`);
                const data = await response.json();
                console.log('News data:', data);
                // setNews(data.feed);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching news:', error);
                setLoading(false);
            }
        };

        fetchNews();
    }, [apiKey]);

    if (loading) {
        return <div>Loading news...</div>;
    }

    return (
        <div className="financial-news">
            <h2>Financial News</h2>
            <ul>
                {news.map((item, index) => (
                    <li key={index}>
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                            {item.title}
                        </a>
                        <p>{item.summary}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FinancialNews;
