import React from 'react';
import Dropdown from '../Dropdown/Dropdown';
import timeframeOptions from '../../constants/timeFrameOptions';

const TimeframeDropdown = ({ selectedTimeframe, onTimeframeChange }) => {
    return (
        <Dropdown
            label="Select Timeframe"
            options={timeframeOptions}
            selectedValue={selectedTimeframe}
            onChange={onTimeframeChange}
        />
    );
};

export default TimeframeDropdown;
