import axios from 'axios';

const BINANCE_WS_BASE_URL = 'wss://stream.binance.com:9443/ws';
const BINANCE_API_URL = 'https://api.binance.com/api/v3';

export const connectToBinanceWebSocket = (symbol, interval, onMessage) => {
  const wsUrl = `${BINANCE_WS_BASE_URL}/${symbol.toLowerCase()}@kline_${interval}`;
  const socket = new WebSocket(wsUrl);

  socket.onmessage = (event) => {
    const message = JSON.parse(event.data);
    if (message.k) {
      onMessage({
        time: message.k.t,
        open: parseFloat(message.k.o),
        high: parseFloat(message.k.h),
        low: parseFloat(message.k.l),
        close: parseFloat(message.k.c),
      });
    }
  };

  return () => {
    socket.close();
  };
};

export const fetchSymbols = async () => {
  const response = await axios.get(`${BINANCE_API_URL}/exchangeInfo`);
  return response.data.symbols.map(symbol => symbol.symbol);
};

export const fetchHistoricalData = async (symbol, interval) => {
  const limit = interval === '1m' ? 1440 : interval === '5m' ? 288 : interval === '15m' ? 96 : 24; // Customize as needed
  const response = await axios.get(`${BINANCE_API_URL}/klines`, {
    params: {
      symbol: symbol.toUpperCase(),
      interval,
      limit
    }
  });
  return response.data.map(d => ({
    time: d[0],
    open: parseFloat(d[1]),
    high: parseFloat(d[2]),
    low: parseFloat(d[3]),
    close: parseFloat(d[4])
  }));
};
