const timeframeOptions = [
    { id:'1', value: '1m', label: '1m' },
    { id:'2', value: '5m', label: '5m' },
    { id:'3', value: '15m', label: '15m' },
    { id:'4', value: '30m', label: '30m' },
    { id:'5', value: '1h', label: '1h' },
    { id:'6', value: '4h', label: '4h' },
    { id:'7', value: '1d', label: '1 Day' },
];

//const timeframes = ['1m', '5m', '15m', '30m', '1h', '4h', '1d'];
export default timeframeOptions;